<template>
  <b-modal
    id="modal-hotline"
    ref="refModal"
    title="Hotline"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên hotline"
              >
                <template
                  v-slot:label
                >
                  Tên hotline
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder="Kỹ thuật"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                label="Số hotline"
              >
                <template
                  v-slot:label
                >
                  Số hotline
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0868987355"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="note"
              rules=""
            >
              <b-form-group
                label="Ghi chú"
              >
                <template
                  v-slot:label
                >
                  Ghi chú
                </template>
                <b-form-input
                  id="note"
                  v-model="itemLocal.note"
                  placeholder="Báo hỏng, hỗ trợ thông tin..."
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.default"
                name="check-button"
                switch
                inline
              >
                Mặc định
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <select-multiple-apartment
              v-model="itemLocal.apartments"
              :default-value="itemLocal.apartments"
            />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectMultipleApartment from '@/views/components/SelectMultipleApartment.vue';
import useHotlineModal from './useHotlineModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,

    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    SelectMultipleApartment,

  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useHotlineModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
